const data = {
  state: [
    {
      name: "Delhi",
      city: [
        "New Delhi",
        "Karawal",
        "Dallupura",
        "Krari Suleman Nagar",
        "Mandoli",
        "Nangloi",
        "Dallupura",
      ],
    },
    {
      name: "West Bengal",
      city: [
        "Kolkata",
        "Berhampore",
        "Siliguri",
        "Durgapur",
        "Burdwan",
        "Malda",
        "Howrah",
        "Haldia",
        "Jalpaiguri",
        "Kharagpur",
        "Asansol",
        "Purulia",
        "North 24 Parganas",
        "Darjeeling",
      ],
    },
    {
      name: "Karnataka",
      city: [
        "Bengaluru Urban",
        "Bengaluru Rural",
        "Habli",
        "Mysuru",
        "Mangaluru",
        "Shivamongga",
        "Udupi",
        "Chikkamangaluru",
        "Belagavi",
        "Ramanagara",
        "Davanagere",
      ],
    },
    {
      name: "Tamil Nadu",
      city: [
        "Chennai",
        "Hosur",
        "Tirupati",
        "Tiruchirappalli",
        "Coimbatore",
        "Madurai",
        "Tiruppur",
        "Tirunelveli",
        "Erode",
        "Salem",
        "Thanjavur",
        "Thoothukudi",
      ],
    },
    {
      name: "Andhra Pradesh",
      city: [
        "Visakhapatnam",
        "Vijayawada",
        "Rajahumundry",
        "Guntur",
        "Tirupati",
        "Kadapa",
        "Nellore",
      ],
    },
    {
      name: "Uttar Pradesh",
      city: [
        "Lucknow",
        "Vrindavan",
        "Agra",
        "Varanasi",
        "Aligarh",
        "Bareilly",
        "Kanpur",
        "Gorakhput",
        "Noida",
        "Jhansi",
        "Prayagraj",
        "Kanpur",
        "Meerut",
        "Ghaziabad",
      ],
    },
    {
      name: "Madhya Pradesh",
      city: [
        "Bhopal",
        "Jabalpur",
        "Ujjain",
        "Gwalior",
        "Chhindwara",
        "Vidisha",
        "Sagar",
        "Indore",
        "Khandwa",
        "Khargone",
        "Katni",
        "Guna",
      ],
    },
    {
      name: "Kerala",
      city: [
        "Kochi",
        "Thiruvananthapuram",
        "Kozhikode",
        "Thrissur",
        "Kollam",
        "Kannur",
        "Alappuzha",
        "Kottayam",
        "Palakkad",
        "Malappuram",
      ],
    },
    {
      name: "Punjab",
      city: [
        "Amritsar",
        "Chandigarh",
        "Kharar",
        "Zirakpur",
        "Chapra",
        "Firozpur",
        "Barnala",
        "Khanna",
        "Moga",
        "Batala",
        "Malerkatla",
        "Abohar",
        "Rupnagar",
        "Hoshiarpur",
        "Gurdaspur",
        "Jalandhar",
        "Rajpura",
        "Phagwara",
        "Mansa",
        "Sangrur",
        "Pathankot",
        "Sri Muktsar Sahib",
        "Ludhiana",
        "Patiala",
        "Bathinda",
        "Mohali",
        "Kapurthala",
      ],
    },
    {
      name: "Assam",
      city: [
        "Guwanhati",
        "Golaghat",
        "Hailakandi",
        "North Lakhimpur",
        "Goalpara",
        "Mangaldoi",
        "Karimganj",
        "Sivasagar",
        "Nalbari",
        "Rangia",
        "Lumding",
        "Lanka",
        "Tezpur",
        "Silchar",
        "Jorhat",
        "Haflong",
        "Diphu",
        "Tinsukia",
        "Nagaon",
        "Bongaigaon",
        "Dhubri",
        "Kokrajhar",
      ],
    },
    {
      name: "Tripura",
      city: [
        "Agartala",
        "Kailashahar",
        "Ranirabazar",
        "Sabroom",
        "Belonia",
        "Dhaarmanagar",
        "Sonamura",
        "Amarpur",
        "Kamalpur",
        "Khowai",
        "Teliamura",
        "Ambassa",
        "Panisagar",
        "Kumarghat",
      ],
    },
    {
      name: "Mizoram",
      city: ["Aizawal", "Lunglei", "Champhai", "Serchhip"],
    },
    {
      name: "Arunachal Pradesh",
      city: ["East Siang", "Itanagar"],
    },
    {
      name: "Goa",
      city: ["Vasco", "Panaji", "Margao"],
    },
    {
      name: "Sikkim",
      city: ["Gangtok", "Namchi"],
    },
    {
      name: "Gujrat",
      city: [
        "Ahmendabad",
        "Vadodara",
        "Rajkot",
        "Vapi",
        "Surat",
        "Dwarka",
        "Jamnagar",
        "Junagadh",
      ],
    },
    {
      name: "Manipur",
      city: ["Imphal"],
    },
    {
      name: "Haryana",
      city: ["Rohtak", "Gurugram", "Fairdabad", "Karnal", "Panipat"],
    },
    {
      name: "Rajasthan",
      city: ["Jaipur", "Udaipur", "Kota", "Bikaner", "Johdhpur"],
    },
    {
      name: "Chhattisgarh",
      city: ["Raipur", "Bhilai", "Jagdalpur", "Bilaspur"],
    },
    {
      name: "Madhya Pradesh",
      city: ["Bhopal", "Jabalpur", "Indore", "Gwalior"],
    },
    {
      name: "Maharashtra",
      city: [
        "Kasheli",
        "Panvel",
        "Pune",
        "Shivane",
        "Nagpur",
        "Thane",
        "Thakurli",
        "Ulhasnagar",
        "Ulwe",
        "Fursungi",
        "Domivli West",
        "Dombivli East",
        "Wagholi",
        "Mumbai",
        "Kalher",
        "Mohali",
        "Solapur",
        "Nashik",
        "Aurangabad",
        "Kolhapur",
        "Ahemdnagar",
        "Amravati",
        "Vasai-Virar",
      ],
    },
    {
      name: "Meghalaya",
      city: ["Shillong"],
    },
    {
      name: "Himachal Pradesh",
      city: ["Shimla", "Dharamshala"],
    },
    {
      name: "Uttrakhand",
      city: [
        "Dehradun",
        "Haridwar",
        "Rishikesh",
        "Rudrapur",
        "Haldwani",
        "Nainital",
      ],
    },
    {
      name: "Bihar",
      city: [
        "Patna",
        "Bihta",
        "Muzaffarpur",
        "Chabra",
        "Darbhanga",
        "Madhubani",
        "Nalanda",
        "Bhagalpur",
      ],
    },
    {
      name: "Odisha",
      city: [
        "Bhubaneswar",
        "Cuttak",
        "Brahmapur",
        "Sambalpur",
        "Puri",
        "Sundarpada",
      ],
    },
    {
      name: "Nagaland",
      city: [
        "Ranchi",
        "Jamshedpur",
        "Jodhpur",
        "Dhanbad",
        "Deoghar",
        "Hazaribagh",
      ],
    },
    {
      name: "Pondicherry",
      city: ["Pondicherry"],
    },
  ],
};

export default data;
